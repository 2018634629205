<template>
  <v-dialog v-model="dialog" max-width="900px" persistent>
    <v-card>
      <v-card-title>
        <span color="primary" class="headline">{{ formTitle }}</span>
      </v-card-title>

      <v-card-text>
        <v-form ref="form" v-model="valid">
          <v-row>
            <v-col cols="6" class="pa-0">
              <v-text-field
                v-model="formData.country"
                :rules="textRules"
                label="البلد"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="6" class="pa-0">
              <v-text-field
                v-model="formData.name"
                :rules="textRules"
                label="الاسم"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="6" class="pa-0">
              <v-text-field
                v-model="formData.encountry"
                :rules="textRules"
                label="country"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="6" class="pa-0">
              <v-text-field
                v-model="formData.enname"
                :rules="textRules"
                label="name"
                required
              ></v-text-field>
            </v-col>
            <v-col md="4">
              <v-file-input
                v-model="logo"
                chips
                label="صورة بارزة للمقال"
              ></v-file-input>
            </v-col>
            <!-- :rules="[(v) => !!v || 'please select an image']" -->

            <v-col md="2">
              <div v-if="file" class="image-area" style="margin-bottom: 10px;">
                <img
                  :src="file.data.full_url"
                  v-if="file"
                  width="250px"
                  height="250px"
                />
                <!-- @click="openLink(img.data.full_url)" -->

                <a
                  class="remove-image"
                  @click="removeimg(file.id, 'file')"
                  href="#"
                  style="display: inline;"
                  >&#215;</a
                >
              </div>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error darken-1" text @click="close">
          إلغاء
        </v-btn>
        <v-btn color="blue darken-1" text @click="submit">
          إرسال
        </v-btn>
      </v-card-actions>
    </v-card>

    <!-- snackbar -->
    <v-snackbar v-model="snackbar" absolute top :multi-line="true">
      {{ error }}

      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-dialog>
</template>

<script>
import ApiService from "@/services/api.service";

export default {
  props: ["formTitle", "record", "dialog", "items", "collection"],
  data() {
    return {
      snackbar: false,
      valid: false,
      submitted: false,
      loading: false,
      error: "",
      loadingcurrencies: false,
      formData: {
        id: null,
        country: null,
        name: null,
        encountry: null,
        enname: null,
        logo: null,
      },
      logo: null,
      file: null,
      currencies: [],
      textRules: [(v) => !!v || "field is required"],

      // =================== for updating users ===================
      initialPayload: {},
    };
  },
  methods: {
    async removeimg(id, text) {
      const { data } = await ApiService.delete(`files/${id}`);
      this.file = null;
      this.formData["logo"] = null;
      this.submit();
    },
    async submit() {
      try {
        if (this.$refs.form.validate()) {
          this.submitted = true;
          if (this.logo) {
            let formData = new FormData();
            formData.append("data", this.logo);
            const fileReq = await ApiService.postFile(
              "files",
              formData,
              0,
              null
            );
            this.formData["logo"] = fileReq.data.data.id;
          }
          if (this.record) {
            await ApiService.update(
              `items/${this.collection}`,
              this.formData.id,
              this.formData
            );
          } else {
            await ApiService.post(`items/${this.collection}`, this.formData);
          }
          this.submitted = false;
          const msg = this.record
            ? "record updated successfully"
            : "record inserted successfully";
          this.$emit("formSubmitted", msg);
        } else {
          this.snackbar = true;
          this.error = "please fill all required fields";
        }
      } catch (error) {
        if (error.message !== "") {
          this.error = error;
        } else {
          this.error = "sorry, something went wrong";
        }
        this.snackbar = true;
        console.log(error);
        this.submitted = false;
      }
    },
    close() {
      this.$emit("closeDialog");
    },
  },

  async mounted() {
    const currencies = await ApiService.get("items/currency?fields=id,title");
    this.currencies = currencies.data.data;

    if (this.record) {
      Object.keys(this.formData).forEach((element) => {
        if (this.record[element]) {
          if (typeof this.record[element] == "object") {
            this.formData[element] = this.record[element].id;
          } else {
            this.formData[element] = this.record[element];
          }
        }
      });
      if (this.formData.logo) {
        const { data } = await ApiService.getFile("files", this.formData.logo);
        this.file = data.data;
      }
    }
  },
};
</script>
<style lang="css">
@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.snow.css";
@import "~quill/dist/quill.bubble.css";
.image-area {
  position: relative;
  width: 50%;
  background: #333;
}
.image-area img {
  max-width: 100%;
  height: auto;
}
.remove-image {
  display: none;
  position: absolute;
  top: -10px;
  right: -10px;
  border-radius: 10em;
  padding: 2px 6px 3px;
  text-decoration: none;
  font: 700 21px/20px sans-serif;
  background: #555;
  border: 3px solid #fff;
  color: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5), inset 0 2px 4px rgba(0, 0, 0, 0.3);
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  -webkit-transition: background 0.5s;
  transition: background 0.5s;
}
.remove-image:hover {
  background: #e54e4e;
  padding: 3px 7px 5px;
  top: -11px;
  right: -11px;
}
.remove-image:active {
  background: #e54e4e;
  top: -10px;
  right: -11px;
}
</style>
